export const config = {
  mixpanel: {
    token: process.env.REACT_APP_MP_TOKEN || "token",
  },
  labels: {
    brandName: process.env.REACT_APP_BRAND_NAME || "AdInsights.ai",
  },
  links: {
    blog: "https://substack.com/@adinsightsai",
  },
  typeform: {
    waitlistForm:
      process.env.REACT_APP_WAITLIST_FORM ||
      "https://f4q4dmiym0t.typeform.com/to/CNuyn2cA",
  },
  videos: {
    demoSource: "https://s3.us-east-1.amazonaws.com/adinsights.ai/demo.mp4",
    demoPoster: "demo-poster.webp",
  },
};
