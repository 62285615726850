import React, { useState, useEffect } from "react";
import {
  Target,
  Check,
  Database,
  Bot,
  TrendingUp,
  DollarSign,
  Shield,
  BarChart,
  Award,
  Mail,
  Zap,
  PieChart,
  RefreshCw,
  // X,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "./config";

const PlatformGrid = () => {
  const platforms = [
    { name: "Google Ads", icon: Database },
    { name: "YouTube", icon: Mail },
    { name: "Facebook", icon: Target },
    { name: "Instagram", icon: PieChart },
    { name: "LinkedIn", icon: Bot },
    { name: "TikTok", icon: Zap },
    { name: "Reddit", icon: RefreshCw },
    { name: "Snapchat", icon: BarChart },
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 max-w-4xl mx-auto mt-12">
      {platforms.map((platform, index) => (
        <div key={index} className="flex flex-col items-center gap-3 group">
          <div className="w-16 h-16 border border-gray-200 flex items-center justify-center transition-all duration-300 group-hover:border-red-600">
            <platform.icon className="w-8 h-8 text-black group-hover:text-red-600 transition-all duration-300" />
          </div>
          <span className="text-sm font-medium text-gray-700">
            {platform.name}
          </span>
        </div>
      ))}
    </div>
  );
};

function LandingPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  // const [activeTab, setActiveTab] = useState<any>("research");
  // const [selectedImage, setSelectedImage] = useState<any>(null);

  useEffect(() => {
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? "bg-white shadow-md" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Shield className="h-8 w-8 text-black" />
            <div className="text-2xl font-bold text-black">AdInsights.ai</div>
          </div>
          <div className="flex">
            <a
              href={config.links.blog}
              target="_blank"
              rel="noreferrer"
              className="text-black px-4 py-2 text-sm font-medium hover:text-red-600"
            >
              Blog
            </a>
          </div>
        </div>
      </header>

      <main className="pt-24">
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center mb-16">
            <div className="flex justify-center gap-2 mb-6">
              <Award className="h-6 w-6 text-red-600" />
              <span className="text-sm font-medium text-red-600">
                Featured in top AI directories
              </span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-black">
              Cut Your Digital Ad Spend by{" "}
              <span className="text-red-600">65%</span>
            </h1>
            <p className="text-2xl text-gray-600 mb-6 max-w-3xl mx-auto">
              AI-powered platform that helps businesses generate, manage, and
              optimize multi-platform ad campaigns without expensive agencies or
              large teams
            </p>

            <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-12">
              <div className="flex items-center gap-2">
                <DollarSign className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">
                  $10,000 → $3,500/month
                </span>
              </div>
              <div className="flex items-center gap-2">
                <TrendingUp className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">2.8x Higher ROAS</span>
              </div>
              <div className="flex items-center gap-2">
                <Database className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">Instant Setup</span>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                onClick={(_: any) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Demo Request Clicked");
                  }
                }}
                className="inline-block bg-black text-white px-8 py-4 text-lg font-medium hover:bg-gray-900 transition-all duration-300"
              >
                Request Demo
              </a>
            </div>
          </div>

          {/* Platform Grid */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-8">
              One Platform,{" "}
              <span className="text-red-600">All Your Ad Channels</span>
            </h2>
            <PlatformGrid />
          </div>

          {/* Demo */}
          {/* <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h2 className="text-4xl font-bold text-center mb-6 text-black">
              See Our AI Agents in Action
            </h2>
            <p className="text-center text-gray-600 italic mb-8">
              Watch how our platform automates campaign creation and
              optimization across multiple ad platforms
            </p>
            <div className="relative aspect-video border border-gray-200 overflow-hidden">
              <video
                controls
                id="demo-video"
                className="w-full h-full fit-cover"
                src={config.videos.demoSource}
                poster={process.env.PUBLIC_URL + config.videos.demoPoster}
                onClick={(_) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Video Player Clicked");
                    (window as any).heap.track("Video Player Clicked");
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </section> */}

          {/* Industry Focus */}
          {/* <div className="bg-white border border-gray-200 p-8 relative overflow-hidden mb-20">
            <div className="absolute top-0 left-0 w-full h-2 bg-red-600"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-3xl font-semibold mb-6 text-black">
                  Perfect for Digital Advertisers:
                </h2>
                <ul className="space-y-6">
                  {[
                    "E-commerce Businesses ($50k-$500k ad spend)",
                    "D2C Brands",
                    "SaaS Companies",
                    "Digital Marketing Teams",
                    "SMB Service Providers",
                    "Growth-Stage Startups",
                    "Marketing Agencies",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 w-8 h-8 bg-red-600 flex items-center justify-center mr-4">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-lg text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative">
                <div className="bg-gray-50 border border-gray-200 p-4">
                  <div className="flex gap-2 mb-4">
                    <button
                      onClick={() => setActiveTab("research")}
                      className={`px-4 py-2 text-sm font-medium ${
                        activeTab === "research"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black"
                      }`}
                    >
                      Research
                    </button>
                    <button
                      onClick={() => setActiveTab("generation")}
                      className={`px-4 py-2 text-sm font-medium ${
                        activeTab === "generation"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black"
                      }`}
                    >
                      Generation
                    </button>
                    <button
                      onClick={() => setActiveTab("analysis")}
                      className={`px-4 py-2 text-sm font-medium ${
                        activeTab === "analysis"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black"
                      }`}
                    >
                      Analysis
                    </button>
                  </div>
                  <div>
                    <div
                      className="aspect-video bg-white border border-gray-200 overflow-hidden cursor-pointer"
                      onClick={() =>
                        setSelectedImage(`${activeTab}-example.webp`)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + `${activeTab}-example.webp`
                        }
                        alt={`${activeTab} screenshot`}
                        className="w-full h-full object-contain"
                      />
                    </div>

                    {selectedImage && (
                      <div
                        className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
                        onClick={() => setSelectedImage(null)}
                      >
                        <button
                          className="absolute top-4 right-4 text-white"
                          onClick={() => setSelectedImage(null)}
                        >
                          <X className="h-6 w-6" />
                        </button>
                        <img
                          src={process.env.PUBLIC_URL + selectedImage}
                          alt="Full size view"
                          className="max-w-full max-h-[90vh] object-contain"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Case Studies */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Real Results from Real Businesses
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  metric: "65% Lower Ad Spend",
                  quote:
                    "E-commerce brand maintained sales volume while reducing monthly ad spend from $15,000 to $5,250 across six platforms",
                },
                {
                  metric: "2.8x ROAS Increase",
                  quote:
                    "D2C company improved return on ad spend from 1.2x to 3.4x through AI-optimized cross-platform campaigns",
                },
                {
                  metric: "40% Higher Conversion",
                  quote:
                    "SaaS startup achieved 40% better conversion rates with AI-generated audience targeting",
                },
              ].map((study, index) => (
                <div
                  key={index}
                  className="bg-white p-8 border border-gray-200"
                >
                  <h3 className="text-xl font-semibold mb-4 text-red-600">
                    {study.metric}
                  </h3>
                  <p className="text-gray-600">{study.quote}</p>
                </div>
              ))}
            </div>
          </div>

          {/* How It Works */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Powered by <span className="text-red-600">Three AI Agents</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: Target,
                  title: "ICP Research Agent",
                  text: "Analyzes your customer data and industry trends to create detailed ideal customer profiles and identify high-potential market segments",
                },
                {
                  icon: Bot,
                  title: "Ad Strategy Agent",
                  text: "Generates complete multi-platform strategies with targeting parameters and budget recommendations for each platform",
                },
                {
                  icon: BarChart,
                  title: "Performance Analysis Agent",
                  text: "Real-time campaign monitoring across all platforms with automated optimization recommendations",
                },
              ].map((step, index) => (
                <div
                  key={index}
                  className="bg-white p-8 border border-gray-200"
                >
                  <div className="mb-4">
                    <step.icon className="h-12 w-12 text-black mx-auto" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 text-center">{step.text}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Pricing */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Simple, Transparent Pricing
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {[
                {
                  name: "Starter",
                  price: "$499",
                  features: [
                    "2 platforms",
                    "$50k monthly ad spend",
                    "Basic optimization",
                    "Email support",
                  ],
                },
                {
                  name: "Growth",
                  price: "$999",
                  highlighted: true,
                  features: [
                    "4 platforms",
                    "$150k monthly ad spend",
                    "Advanced optimization",
                    "Priority support",
                  ],
                },
                {
                  name: "Scale",
                  price: "$1,999",
                  features: [
                    "All platforms",
                    "Unlimited ad spend",
                    "Custom strategies",
                    "Dedicated success manager",
                  ],
                },
              ].map((plan, index) => (
                <div
                  key={index}
                  className={`bg-white p-8 ${
                    plan.highlighted
                      ? "border-2 border-red-600"
                      : "border border-gray-200"
                  }`}
                >
                  <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
                  <p className="text-3xl font-bold mb-6">
                    {plan.price}
                    <span className="text-base font-normal text-gray-600">
                      /month
                    </span>
                  </p>
                  <ul className="space-y-4">
                    {plan.features.map((feature, i) => (
                      <li key={i} className="flex items-center gap-2">
                        <Check className="h-5 w-5 text-black" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <a
                    href={config.typeform.waitlistForm}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(_: any) => {
                      if (process.env.NODE_ENV === "production") {
                        mixpanel.track("Plan Selected");
                      }
                    }}
                    className={`block text-center mt-8 px-6 py-3 font-medium transition-all duration-300 ${
                      plan.highlighted
                        ? "bg-red-600 text-white hover:bg-red-700"
                        : "bg-black text-white hover:bg-gray-900"
                    }`}
                  >
                    Request Demo
                  </a>
                </div>
              ))}
            </div>
            <p className="text-center text-gray-500 mt-8">
              All plans include: 14-day free trial • No long-term contract •
              Money-back guarantee
            </p>
          </div>

          {/* Final CTA */}
          <div className="bg-black text-white p-12 my-20">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-6">
                Ready to Transform Your Digital Advertising?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                <div className="p-6 border border-gray-700">
                  <div className="text-3xl font-bold mb-2">65%</div>
                  <div className="text-red-500">Average Cost Reduction</div>
                </div>
                <div className="p-6 border border-gray-700">
                  <div className="text-3xl font-bold mb-2">2.8x</div>
                  <div className="text-red-500">Typical ROAS Improvement</div>
                </div>
                <div className="p-6 border border-gray-700">
                  <div className="text-3xl font-bold mb-2">Instant</div>
                  <div className="text-red-500">Campaign Generation</div>
                </div>
              </div>
              <div className="space-y-4">
                <a
                  href={config.typeform.waitlistForm}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(_: any) => {
                    if (process.env.NODE_ENV === "production") {
                      mixpanel.track("Final CTA Clicked");
                    }
                  }}
                  className="inline-block bg-red-600 text-white px-8 py-4 font-medium hover:bg-red-700 transition-all duration-300"
                >
                  Request Demo
                </a>
                <p className="text-sm text-gray-400">
                  Limited spots available - Implementing with 20 companies this
                  month
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center gap-2 mb-4 md:mb-0">
              <Shield className="h-6 w-6 text-black" />
              <span className="text-lg font-bold text-black">
                AdInsights.ai
              </span>
            </div>
            <p className="text-sm text-gray-600">
              © {new Date().getFullYear()} AdInsights.ai. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
